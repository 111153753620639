import firebase from "firebase";
import { retryWithBackoff } from "./retryUtils";

// Load all Okayars
export const loadOkayars = async (
  selectedQuarter: number,
  selectedYear: number,
  currentUser: firebase.User
) => {
  const fetchOperation = async () => {
    const options = {
      method: "GET",
      headers: new Headers(),
    };
    let id_token = await currentUser.getIdToken(true);
    options.headers.append("Authorization", `Bearer ${id_token}`);

    let response = await fetch(
      `${process.env.REACT_APP_OKAYAR_BACKEND_URL}/v1/objectives?quarter=${selectedQuarter}&year=${selectedYear}`,
      options
    );
    if (!response.ok) {
      const error = new Error(
        `An error has occurred: ${response.status}`
      ) as Error & { status?: number };
      error.status = response.status;
      throw error;
    }

    return response.json();
  };

  return retryWithBackoff(fetchOperation);
};

// Objective funcs
export const addNewObjective = async (
  newObjective: string,
  selectedQuarter: number,
  selectedYear: number,
  currentUser: firebase.User
) => {
  const fetchOperation = async () => {
    const data = {
      description: newObjective,
      year: selectedYear,
      quarter: selectedQuarter,
    };

    const options = {
      method: "POST",
      headers: new Headers(),
      body: JSON.stringify(data),
    };
    let id_token = await currentUser.getIdToken(true);
    options.headers.append("Content-Type", "application/json");
    options.headers.append("Authorization", `Bearer ${id_token}`);

    let response = await fetch(
      `${process.env.REACT_APP_OKAYAR_BACKEND_URL}/v1/objectives`,
      options
    );
    if (!response.ok) {
      const error = new Error(
        `An error has occurred: ${response.status}`
      ) as Error & { status?: number };
      error.status = response.status;
      throw error;
    }

    return response.json();
  };

  return retryWithBackoff(fetchOperation);
};

export const editObjective = async (
  objectiveId: number,
  newObjectiveTitle: string,
  currentUser: firebase.User
) => {
  const fetchOperation = async () => {
    const data = {
      description: newObjectiveTitle,
    };

    const options = {
      method: "PATCH",
      headers: new Headers(),
      body: JSON.stringify(data),
    };
    let id_token = await currentUser.getIdToken(true);
    options.headers.append("Content-Type", "application/json");
    options.headers.append("Authorization", `Bearer ${id_token}`);

    let response = await fetch(
      `${process.env.REACT_APP_OKAYAR_BACKEND_URL}/v1/objectives/${objectiveId}`,
      options
    );
    if (!response.ok) {
      const error = new Error(
        `An error has occurred: ${response.status}`
      ) as Error & { status?: number };
      error.status = response.status;
      throw error;
    }

    return response.json();
  };

  return retryWithBackoff(fetchOperation);
};

export const deleteObjective = async (
  objectiveId: number,
  currentUser: firebase.User
) => {
  const fetchOperation = async () => {
    const options = {
      method: "DELETE",
      headers: new Headers(),
    };
    let id_token = await currentUser.getIdToken(true);
    options.headers.append("Content-Type", "application/json");
    options.headers.append("Authorization", `Bearer ${id_token}`);

    let response = await fetch(
      `${process.env.REACT_APP_OKAYAR_BACKEND_URL}/v1/objectives/${objectiveId}`,
      options
    );
    if (!response.ok) {
      const error = new Error(
        `An error has occurred: ${response.status}`
      ) as Error & { status?: number };
      error.status = response.status;
      throw error;
    }

    return response.text();
  };

  return retryWithBackoff(fetchOperation);
};

// Key Result funcs
export const createKeyResult = async (
  objectiveId: number,
  newKeyResultDescription: string,
  newKeyResultNumericGoal: number,
  currentUser: firebase.User
) => {
  const fetchOperation = async () => {
    const data = {
      description: newKeyResultDescription,
      numeric_goal: newKeyResultNumericGoal,
      numeric_progress: 0,
    };

    const options = {
      method: "POST",
      headers: new Headers(),
      body: JSON.stringify(data),
    };
    let id_token = await currentUser.getIdToken(true);
    options.headers.append("Content-Type", "application/json");
    options.headers.append("Authorization", `Bearer ${id_token}`);

    let response = await fetch(
      `${process.env.REACT_APP_OKAYAR_BACKEND_URL}/v1/objectives/${objectiveId}/key-results`,
      options
    );
    if (!response.ok) {
      const error = new Error(
        `An error has occurred: ${response.status}`
      ) as Error & { status?: number };
      error.status = response.status;
      throw error;
    }

    return response.json();
  };

  return retryWithBackoff(fetchOperation);
};

export const editKeyResult = async (
  objectiveId: number,
  keyResultId: number,
  newKeyResultDescription: string,
  newKeyResultNumericProgress: number,
  newKeyResultNumericGoal: number,
  currentUser: firebase.User
) => {
  const fetchOperation = async () => {
    const data = {
      description: newKeyResultDescription,
      numeric_goal: newKeyResultNumericGoal,
      numeric_progress: newKeyResultNumericProgress,
    };

    const options = {
      method: "PATCH",
      headers: new Headers(),
      body: JSON.stringify(data),
    };
    let id_token = await currentUser.getIdToken(true);
    options.headers.append("Content-Type", "application/json");
    options.headers.append("Authorization", `Bearer ${id_token}`);

    let response = await fetch(
      `${process.env.REACT_APP_OKAYAR_BACKEND_URL}/v1/objectives/${objectiveId}/key-results/${keyResultId}`,
      options
    );
    if (!response.ok) {
      const error = new Error(
        `An error has occurred: ${response.status}`
      ) as Error & { status?: number };
      error.status = response.status;
      throw error;
    }

    return response.json();
  };

  return retryWithBackoff(fetchOperation);
};

export const deleteKeyResult = async (
  objectiveId: number,
  keyResultId: number,
  currentUser: firebase.User
) => {
  const fetchOperation = async () => {
    const options = {
      method: "DELETE",
      headers: new Headers(),
    };
    let id_token = await currentUser.getIdToken(true);
    options.headers.append("Content-Type", "application/json");
    options.headers.append("Authorization", `Bearer ${id_token}`);

    let response = await fetch(
      `${process.env.REACT_APP_OKAYAR_BACKEND_URL}/v1/objectives/${objectiveId}/key-results/${keyResultId}`,
      options
    );
    if (!response.ok) {
      const error = new Error(
        `An error has occurred: ${response.status}`
      ) as Error & { status?: number };
      error.status = response.status;
      throw error;
    }

    return response.text();
  };

  return retryWithBackoff(fetchOperation);
};
