import React from 'react';
import styled from 'styled-components';

import firebase from 'firebase';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';

import { Redirect } from "react-router-dom";

import { AuthContext } from '../auth'
import PageHeader from './page-header'
import PrivacyAndTerms from './privacy-terms-buttons'
import { OkayarHomeComponent, PageBodyWrapper } from './common-styled-divs'
import { primary, offWhite } from '../colors'

export interface LoginProps {}

const Login = (props: LoginProps) => {   
    const {currentUser} = React.useContext(AuthContext)
    if (!!currentUser) {
        return (
            <Redirect to="/" />
        )
    }

    const uiConfig = {
        signInFlow: 'popup',
        signInSuccessUrl: '/',
        signInOptions: [
          firebase.auth.EmailAuthProvider.PROVIDER_ID,
          firebase.auth.GoogleAuthProvider.PROVIDER_ID,
          firebase.auth.FacebookAuthProvider.PROVIDER_ID,
        ],
    }

    document.title = "Okayar - Login"

    return (
        <OkayarHomeComponent>
            <PageHeader hideLogout />
            <PageBodyWrapper>
                <AuthStyleWrapper>
                    <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} />
                    <PrivacyAndTerms />
                </AuthStyleWrapper>
            </PageBodyWrapper>
        </OkayarHomeComponent>
    )
}

const AuthStyleWrapper = styled.div`
    width: 40%;
    min-width: 275px;
    margin-top: 1.5rem;

    background: ${offWhite};
    border-radius: 20px;
    padding: 1.5rem;
    text-align: justify;

    .firebaseui-textfield.mdl-textfield .firebaseui-label:after {
        background-color: ${primary};
    }

    .mdl-button--raised.mdl-button--colored:active, .mdl-button--raised.mdl-button--colored:focus:not(:active), .mdl-button--raised.mdl-button--colored:hover {
        background-color: ${primary};
    }

    .mdl-button--raised.mdl-button--colored {
        background: ${primary};
    }

    .mdl-button--primary.mdl-button--primary {
        color: ${primary};
    }
`

export default Login;